import React, { createContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import Apiconfig from "src/ApiConfig/ApiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { getWeb3ContractObject } from "src/utils";
import { marketplaceContractAddress } from "src/constants";
import MarketplaceABI from "src/ABI/MarketplaceABI.json";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, deactivate, library, chainId } = useWeb3React();
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [contractOwner, setContractOwner] = useState("");
  const [promotionFee, setPromotionFee] = useState({});
  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector;
      localStorage.setItem("walletName", data?.name);
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };

  //-----*WEB3-Connection-Disable*-----//
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      // toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  const connectToAPIWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(Apiconfig.connectWallet, {
        walletAddress,
      });
      if (res.data.responseCode === 200) {
        setTokenSession(res.data.result.token);
        getProfileHandler(res.data.result.token);
        window.localStorage.setItem("userType", res.data.result.userType);
        window.localStorage.setItem("userAddresstoken", res.data.result.token);
        if (!window.sessionStorage.getItem("walletName")) {
          // toast.success("Connected succesfully");
        } else {
          toast.error(res.data.responseMessage);
          deactivate();
        }
      } else {
        setIsLoading(false);
        deactivate();
      }
    } catch (error) {
      deactivate();
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(Apiconfig.getProfile, {
        headers: {
          token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      setTimeout(() => {
        setIsLoading(false);
      });
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
    }
  };
  const getContractBasicDetailsHandler = async () => {
    try {
      const contractObj = await getWeb3ContractObject(
        MarketplaceABI,
        marketplaceContractAddress
      );
      const ownerFun = await contractObj.methods.owner().call();
      setContractOwner(ownerFun);
    } catch (error) {
      console.log(error);
    }
  };

  const getCommissionFeeHandler = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: Apiconfig.getPromotionFee,
      });
      if (response) {
        setPromotionFee(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let data = {
    userData,
    isLoading,
    contractOwner,
    promotionFee,
    isOpenConnectWallet,
    updateUser: (account) => {
      setSession(account);
    },
    getProfileHandler: (token) => getProfileHandler(token),
    // disconnectWallet: () => disconnectWalletHandler(),
    disconnectWallet: () => {
      disconnectWalletHandler();
      setIsLoading(false);
    },
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),
    connectWallet: (item) => connectWalletHandler(item),
  };
  useEffect(() => {
    getContractBasicDetailsHandler();
    getCommissionFeeHandler();
  }, []);

  useEffect(() => {
    if (account) {
      connectToAPIWalletHandler(account);
    } else {
      setIsLogin(false);
    }
  }, [account]);

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account) {
      setIsOpenConnectWallet(false);
    }
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
