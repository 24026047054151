import React, { useState, useContext } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  List,
  Link,
  ListItem,
  InputBase,
  IconButton,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { TiSocialFacebook } from "react-icons/ti";
import { RiInstagramLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { TiSocialYoutube } from "react-icons/ti";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import Apiconfig from "src/ApiConfig/ApiConfig";
import axios from "axios";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { Form, Formik } from "formik";
import * as yep from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import { UserContext } from "src/context/User";
import { nativeTokenName } from "src/constants";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#000000",
    position: "relative",
    padding: "50px 0px",
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& h4": {
      fontSize: "20px",
      color: "#E7DDCE",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    "& h6": {
      width: " 100%",
      maxWidth: "392px",
      marginTop: "10px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        fontSize: "14px",
      },
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  textFild: {
    position: "relative",
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#F5C843",
      minWidth: "128px",
      fontSize: "13px",
      fontWeight: "300",
      color: "#000000",
      borderRadius: "100px",
      fontFamily: "'Michroma', sans-serif",
      "&:hover": {
        backgroundColor: "#F5C843",
      },
    },
    "& .MuiOutlinedInput-root": {
      background: "#3A3A3A",
      borderRadius: "100px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 10px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "100px",
    },
  },
  listbox: {
    background: "none",
    border: "none",
  },
  listitem: {
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#fff",
    cursor: "pointer",
    background: "none",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    fontFamily: "'Ubuntu', sans-serif",
    paddingBottom: "16px",
    "@media(max-width:599px)": {
      fontSize: "14px",
    },
  },
  iconboxes: {
    width: "32px",
    height: "32px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#8A8780",
    padding: "4px",
    marginRight: "8px",
    marginTop: "15px",
    "& .iconcenter": {
      width: "26px",
      height: "26px",
      borderRadius: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#8A8780",
      border: "2px solid #000",
      padding: "2px",
      "& svg": {
        color: "#000",
      },
    },
  },
  flexicons: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "16px",
    },
  },
  staticcontent: {
    "& h5": {
      color: "rgba(255, 255, 255, 0.6)",
      marginBottom: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    "& .borderbox": {
      borderRight: "1px solid rgba(255, 255, 255, 0.1)",
      margin: "0px 16px",
      height: "30px",
    },
    "& .boxxes": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "20px",
      "@media(max-width:707px)": {
        display: "block",
      },
    },
  },
  chakboxbtn: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#585858",
    },
    "& h6": {
      fontSize: "14px",
      fontWeight: "300",
      marginTop: "0px",
      maxWidth: "100%",
      "& a": {
        color: "#F5C843",
      },
    },
  },
  usefull: {
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const formInitialSchema = {
    email: "",
    termsAndConditions: false
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required.")
      .min(3, "Please enter atleast 3 charachters.")
      .max(256, "You can only enter maximum 256 charachters."),
      termsAndConditions: yep.boolean()
      .required("You must accept the terms and conditions to proceed.")
      .oneOf([true], "You must accept the terms and conditions to proceed."),
  });
  const submitHandler = async (values) => {
    try {
      setIsUploading(true);
      const response = await axios({
        method: "POST",
        url: Apiconfig.userSubscribe,
        params: {
          email: values.email,
        },
      });
      if (response.data.responseCode === 200) {
        console.log("response----", response);
        setIsUploading(false);
        toast.success("You have successfully subscribed for the letter");
      }
    } catch (error) {
      console.log(error.response);
      setIsUploading(false);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={7} md={5}>
              <img
                alt=""
                src="images/logo.png"
                style={{ width: "100%", maxWidth: "60px" }}
              />
              <Typography variant="h6">
                {`${nativeTokenName} token is pure play AI powered web3.0 and metaverse platform
                for music video/Live online concerts Industry, which is
                integrating creators (Producer, Artists, Singer, Performers and
                other stakeholders) as IPR/Copyrights for particular music
                video/Live concerts with Consumers and Investment community`}
              </Typography>
              <Box className={classes.flexicons}>
                <Box className={classes.iconboxes}>
                  <Box className="iconcenter">
                    <IconButton target="_blank" href="https://www.facebook.com">
                      <TiSocialFacebook />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.iconboxes}>
                  <Box className="iconcenter">
                    <IconButton
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <RiInstagramLine />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.iconboxes}>
                  <Box className="iconcenter">
                    <IconButton
                      target="_blank"
                      href="https://web.whatsapp.com/"
                    >
                      <FaWhatsapp />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.iconboxes}>
                  <Box className="iconcenter">
                    <IconButton target="_blank" href="https://www.youtube.com/">
                      <TiSocialYoutube />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="h4">Useful Link</Typography>
              <Box className={classes.usefull}>
                <List className={classes.listbox}>
                  {/* <RouterLink style={{ textDecoration: "none" }}>
                    <ListItem
                      className={classes.listitem}
                      component={RouterLink}
                      to="/user-profile"
                    >
                      User Profile
                    </ListItem>
                  </RouterLink> */}
                  <RouterLink style={{ textDecoration: "none" }}>
                    <ListItem
                      className={classes.listitem}
                      component={RouterLink}
                      to="/marketplace"
                    >
                      Marketplace{" "}
                    </ListItem>
                  </RouterLink>
                  {/* <RouterLink style={{ textDecoration: "none" }}>
                    <ListItem
                      className={classes.listitem}
                      component={RouterLink}
                      to="/create-nft"
                    >
                      Create NFT
                    </ListItem>
                  </RouterLink> */}
                  <RouterLink style={{ textDecoration: "none" }}>
                    <ListItem
                      className={classes.listitem}
                      component={RouterLink}
                      to="/my-profile"
                    >
                      Profile
                    </ListItem>
                  </RouterLink>
                  <RouterLink style={{ textDecoration: "none" }}>
                    <ListItem
                      className={classes.listitem}
                      component={RouterLink}
                      to="/live-concerts"
                    >
                      {" "}
                      Live Concerts
                    </ListItem>
                  </RouterLink>
                </List>{" "}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h4">
                {`Be the first to know about ${nativeTokenName}`}
              </Typography>
              <Formik
                initialValues={formInitialSchema}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => submitHandler(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form className={classes.root}>
                    <Box mt={3} className={classes.textFild}>
                      <InputBase
                        id="outlined-basic"
                        fullWidth
                        variant="outlined"
                        placeholder="Enter your email here..."
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <Button
                              type="submit"
                              // disabled={isUploading || !termsAndConditions}
                            >
                              {isUploading ? (
                                <>
                                  Please wait...
                                  <ButtonCircularProgress />
                                </>
                              ) : (
                                "Join now"
                              )}
                            </Button>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>

                    <Box pr={4} className={classes.chakboxbtn}>
                      <Checkbox
                        checked={values.termsAndConditions} 
                        name="termsAndConditions"
                        onChange={handleChange}
                      />
                      <Typography variant="h6">
                        I agree to the{" "}
                        <Link target="_blank" href="/terms-conditions">
                          terms and conditions
                        </Link>{" "}
                        and{" "}
                        <Link target="_blank" href="/privacy-policy">
                          privacy policy.
                        </Link>
                      </Typography>
                  
                    </Box>
                    <FormHelperText error className={classes.helperText}>
                        {touched.termsAndConditions && errors.termsAndConditions}
                      </FormHelperText>
                  </Form>
                )}
              </Formik>
            </Grid>

          </Grid>
          <Box mt={3} mb={1}>
            <Divider />
          </Box>
          <Box className={classes.staticcontent}>
            <Box className="boxxes">
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                  component={Link}
                  target="_blank"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </Typography>
                <Box className="borderbox"></Box>
                <Typography
                  variant="h5"
                  component={Link}
                  target="_blank"
                  href="/terms-conditions"
                >
                  Terms & Conditions
                </Typography>
              </Box>
              <Typography variant="h5">
                {`Copyright©${moment().format("YYYY")}. Created with love by`}{" "}
                <span style={{ color: "#F5C843" }}>{nativeTokenName}</span>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
