import detectEthereumProvider from "@metamask/detect-provider";
export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const RPC_URL = "https://data-seed-prebsc-2-s1.binance.org:8545/";

export const marketplaceContractAddress =
  "0xa38c2913dC2562c3BE58eCC108f7cAEC86536e62"; //
export const nativeTokenAddress = "0xDff1538463e5D154492e4d38839abDe2c7533Cd8";
export const nativeTokenName = "MOMG";
export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "BSC-Testnet",
    nativeCurrency: {
      name: "BSC-Testnet",
      symbol: "TBNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
];
export const addTokenhandler = async (event) => {
  const provider = await detectEthereumProvider();
  const isAdded = provider.sendAsync(
    {
      method: "metamask_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: nativeTokenAddress,
          symbol: nativeTokenName,
          decimals: 18,
          image: "",
        },
      },
      id: Math.round(Math.random() * 100000),
    },
    (err, added) => {
      if (added.result) {
        return true;
      } else {
        return false;
      }
    }
  );
  return isAdded;
};
