import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  Box,
  Grid,
  Typography,
  Button,
  Container,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SUPPORTED_WALLETS } from "src/connectors";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: "2.5px",
    backgroundImage:
      "radial-gradient(54.8% 53% at 50% 50%, #ffeb3b 0%, rgba(245, 200, 67, 0) 100%), radial-gradient(60% 51.57% at 50% 50%, #F5C843 0%, rgb(255 191 0 / 2%) 100%),radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
    "& .root": {
      padding: "15px",
      position: "relative",
      // background: "linear-gradient(152.97deg, rgb(31 31 31) 0%, rgb(0 0 0) 100%)",
      borderRadius: "15px",
      backdropFilter: "blur(21px)",
      backgroundColor: "#000000",
      overflow: "auto",
    },
  },
  closeIcon: {
    position: "absolute",
    right: "0px",
    top: "0px",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  ImageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& figure": {
      margin: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      overflow: "hidden",
      "& img": {
        width: "100%",
        maxWidth: "339px",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          maxWidth: "220px !important",
        },
      },
    },
  },
  ContentBox: {
    padding: "60px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
    },
    "& h4": {
      color: "#FFFFFF",
    },
    "& p": {
      color: "#FFFFFF",
      marginTop: "10px",
    },
    "& .buttonBox": {
      padding: "40px 0px",
      "& .walletButton": {
        background: "rgba(255, 255, 255, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.05)",
        borderRadius: "15px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: "30px",
        cursor: "pointer",
        "& h5": {
          color: "#FFFFFF",
          marginTop: "8px",
        },
        "& figure": {
          margin: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50px",
          height: "50px",
          borderRadius: "10px",
          overflow: "hidden",
          "& img": {
            width: "100%",
            minHeight: "100%",
          },
        },
      },
    },
  },
}));

function ConnectWalletModal({ openModal, handleCloseModal, user }) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openModal}
        maxWidth="md"
        onClose={handleCloseModal}
        fullWidth
        disableScrollLock
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <Box className="root">
          <IconButton onClick={handleCloseModal} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className={classes.ImageBox}>
                <figure>
                  <img src="images/logo.png" alt="Connect Wallet Image" />
                </figure>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className={classes.ContentBox}>
                <Typography variant="h4">Connect your Wallet</Typography>
                <Typography variant="body1">
                  Choose your wallet, that you want to connect.
                </Typography>
                <Box className="buttonBox">
                  <Grid container spacing={2}>
                    {SUPPORTED_WALLETS.map((data, i) => {
                      return (
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box
                            className="walletButton"
                            onClick={() => user.connectWallet(data.data)}
                          >
                            <figure>
                              <img src={data.data.icon} alt="Metamask Image" />
                            </figure>
                            <Typography variant="h5">
                              {data.data.name}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Box mt={2} align="center">
                  <Typography variant="body1" style={{ color: "#F5C843" }}>
                    What is wallet?
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}

export default ConnectWalletModal;
