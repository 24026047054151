// export const baseurl = "https://node-musicmarketplace.mobiloitte.org/";
// export const baseurl = "https://node.mbitdigital.com/";
export const baseurl = "https://node.momg.live/";
// export const baseurl = "http://172.16.0.128:1941/";

let user = `${baseurl}api/v1/user`;
let nft = `${baseurl}api/v1/nft`;
let order = `${baseurl}api/v1/order`;
let category = `${baseurl}api/v1/category`;
let admin = `${baseurl}api/v1/admin`;
let staticData = `${baseurl}api/v1/static`;
let joinUs = `${baseurl}api/v1/joine`;

const Apiconfigs = {
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  topSeller: `${user}/topSeller`,
  topBuyer: `${user}/topBuyer`,
  allListOrder: `${user}/allListOrder`,
  buyOrder: `${user}/buyOrder`,
  activityList: `${user}/activityList`,
  followUnfollowUser: `${user}/followUnfollowUser`,
  userFollowerCount: `${user}/userFollowerCount`,
  eventUserListPast: `${user}/eventListPast`,
  uploadMultipleFile: `${user}/uploadMultipleFile`,
  // -----------NFT----------
  mylistNft: `${nft}/mylistNft`, //created nf
  listNft: `${nft}/listNft`, //onsale nft
  buynftlist: `${nft}/buynftlist`, //bought nft
  viewNft: `${nft}/viewNft`,
  updateNft: `${nft}/updateNft`,
  uploadFile: `${user}/uploadFile`,
  ipfsUpload: `${nft}/ipfsUpload`,
  createNft: `${nft}/createNft`,

  //-------KYC------//
  addKYC: `${user}/addKYC`,
  viewKycUser: `${user}/viewKyc`,
  editKYC: `${user}/editKYC`,

  //order
  putOnsale: `${order}/putOnsale`,
  listOrder: `${order}/listOrder`,
  mylistOrder: `${order}/mylistOrder`,
  viewOrder: `${order}/viewOrder`,
  topBuyerSaler: `${order}/topBuyerSaler`,
  priceHistory: `${order}/priceHistory`,
  resaleOrder: `${order}/resaleOrder`,
  cancelOrder: `${order}/cancelOrder`,
  orderSaleList: `${order}/orderSaleList`,
  promotNft: `${order}/promotNft`,
  boughtNft: `${order}/boughtNft`,
  boughtNft: `${order}/boughtNft`,

  //category
  categoryList: `${category}/categoryList`,

  ///concert management
  listkyc: `${admin}/listkyc`,
  viewKyc: `${admin}/viewKyc`,
  approveRejectKyc: `${admin}/approveRejectKyc`,
  userList: `${admin}/listUser`,
  concertList: `${admin}/eventListPast`,
  deleteConcertList: `${admin}/deleteEvent`,
  createLiveConcert: `${admin}/createEvent`,
  updateConcert: `${admin}/editEvent`,

  languageList: `${admin}/languageList`,
  viewUser: `${admin}/viewUser`,
  updatePromotionFee: `${admin}/updatePromotionFee`,
  getPromotionFee: `${admin}/getPromotionFee`,
  staticContentList: `${staticData}/staticContentList`,
  editStaticData: `${staticData}/staticContent`,
  eventNotify: `${user}/eventNotify`,
  viewConcert: `${admin}/viewEvent`,
  uploadFile: `${user}/uploadFile`,
  kycApproveReject: `${admin}/kycApproveReject`,
  //////////JoinUs
  userSubscribe: `${joinUs}/joineEvent`,
};

export default Apiconfigs;
